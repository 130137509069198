import React, { useState } from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import DropdownSelect from '../DropdownSelect';
import { Editor } from '@tinymce/tinymce-react';
import { Select } from 'antd';

const { Option } = Select;

const apiKey = process.env.REACT_APP_TINYMCE_APIKEY;

export const CreateSubcategory = ({ createSubcategoryProps, createFormError, createSubcategoryFormRef, handleOnRemoveUploadFeatureImage, handleOnRemoveUpload, imageData, featureImageData, handleOnSubmit, createSubcategoryForm, handleOnChange, handleOnBlur, createSubcategoryFormError, attributes, toggleSubcategoryCreate, handleOnSelect, setSelected, setToggleSubcategoryCreate, createSubBtnLoader, disabledCreateSub, handleOnChangeAttributesSubcategory }) => {
  const [description, setDescription] = useState('');
  const getTextValue = (value) => {
    if (value !== description) {
      setDescription(value);
    }
  };

  return (
    <SidebarModal {...createSubcategoryProps} className="sidebar__modal--right form-subcategory" title="Kreiraj podkategoriju">
      <form
        name="createSubcategory"
        ref={createSubcategoryFormRef}
        className="ant-form ant-form-horizontal"
        onSubmit={(event) =>
          handleOnSubmit({
            event: event,
            formRef: createSubcategoryFormRef,
          })
        }
      >
        <div className="form-group relative">
          <p>
            <b>Ikonica</b>
          </p>
          <div className={`form-group upload-image`}>
            {imageData.blob ? (
              <div className="avatar">
                <div className="image-wrapper">
                  <img src={imageData.blob} alt="Parent category blob" data-cy="actual-image" className="img" />
                </div>

                <button type="button" data-cy="remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'create' })}>
                  <span className="icon" data-cy="image-placeholder"></span>
                  <span className="text">Izbriši Fotografiju</span>
                </button>
              </div>
            ) : (
              <div className="upload">
                <div className="upload-wrapper d-flex gap-x-2">
                  <div>
                    <span className="text">
                      <div className="text-center">
                        <p className="mb-0">Nema izabrane</p>
                        <p className="mb-0">fotografije</p>
                      </div>
                    </span>
                    <input
                      type="file"
                      name="createImage"
                      id="uploadFile"
                      className="input-upload"
                      onChange={(event) =>
                        handleOnUpload({
                          name: event.target.name,
                          img: event.target.files[0],
                        })
                      }
                    />
                  </div>

                  <div className="validation-image">
                    <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageType ? 'error' : ''}`}>
                      * dozvoljni su samo JPG i PNG formati!
                    </p>

                    <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageSize ? 'error' : ''}`}>
                      * slika mora biti manja od 2MB!
                    </p>
                  </div>
                </div>
                <label htmlFor="uploadFile" data-cy="upload-image-btn" className="label btn btn-primary-outline btn-no-hover">
                  <span className="icon">
                    <img src="/assets/icons/upload.svg" alt="Upload parent category" className="img" />
                  </span>
                  <span className="text">Objavi sliku</span>
                </label>
              </div>
            )}
          </div>
          <p>
            <b>Slika kategorije</b>
          </p>
          <div className={`form-group upload-image`}>
            {featureImageData.blob ? (
              <div className="avatar">
                <div className="image-wrapper">
                  <img src={featureImageData.blob} alt="Parent category blob" data-cy="actual-image" className="img" />
                </div>

                <button type="button" data-cy="remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUploadFeatureImage({ name: 'create' })}>
                  <span className="icon" data-cy="image-placeholder"></span>
                  <span className="text">Izbriši Fotografiju</span>
                </button>
              </div>
            ) : (
              <div className="upload">
                <div className="upload-wrapper d-flex gap-x-2">
                  <div>
                    <span className="text">
                      <div className="text-center">
                        <p className="mb-0">Nema izabrane</p>
                        <p className="mb-0">fotografije</p>
                      </div>
                    </span>
                    <input
                      type="file"
                      name="createImage"
                      id="uploadFileSecond"
                      className="input-upload"
                      onChange={(event) =>
                        handleOnUploadFeatureImage({
                          name: event.target.name,
                          img: event.target.files[0],
                        })
                      }
                    />
                  </div>

                  <div className="validation-image">
                    <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageType ? 'error' : ''}`}>
                      * dozvoljni su samo JPG i PNG formati!
                    </p>

                    <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageSize ? 'error' : ''}`}>
                      * slika mora biti manja od 2MB!
                    </p>
                  </div>
                </div>
                <label htmlFor="uploadFileSecond" data-cy="upload-image-btn" className="label btn btn-primary-outline btn-no-hover">
                  <span className="icon">
                    <img src="/assets/icons/upload.svg" alt="Upload parent category" className="img" />
                  </span>
                  <span className="text">Objavi sliku</span>
                </label>
              </div>
            )}
          </div>

          <label className="label mb-1 d-block" htmlFor="createSubcategoryName">
            <b className="required">Naziv:</b>
          </label>

          <input name="createSubcategoryName" id="createSubcategoryName" value={createSubcategoryForm.createSubcategoryName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Name" data-cy="name-create-subcategory-input" className={`input ${createSubcategoryFormError.createSubcategoryName || createSubcategoryFormError.uniqueSubcategoryName ? 'input-error' : ''}`} />
          <p data-cy="modal-name-create-subcategory-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategoryName ? '' : createSubcategoryFormError.createSubcategoryName ? 'error' : ''}`}>
            * Ovo polje je obavezno!
          </p>
          <p data-cy="modal-name-create-subcategory-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategoryName ? 'error' : ''}`}>
            * Naziv ove kategorije već postoji!
          </p>
        </div>

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createSubcategorySlug">
            <b className="required">Slug:</b>
          </label>

          <input name="createSubcategorySlug" id="createSubcategorySlug" value={createSubcategoryForm.createSubcategorySlug || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Slug" data-cy="slug-create-subcategory-input" className={`input ${createSubcategoryFormError.uniqueSubcategorySlug || createSubcategoryFormError.createSubcategorySlug ? 'input-error' : ''}`} />

          <p data-cy="modal-create-category-slug-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategorySlug ? '' : createSubcategoryFormError.createSubcategorySlug ? 'error' : ''}`}>
            * Ovo polje je obavezno!
          </p>
          <p data-cy="modal-create-category-slug-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategorySlug ? 'error' : ''}`}>
            * Ovaj slug već postoji!
          </p>
        </div>

        <div className="form-group form-group-selection-items">
          <label className="label mb-1 d-block" htmlFor="createSubcategoryAttributes">
            <b>Atributi:</b>
          </label>

          <Select mode="multiple" defaultValue={createSubcategoryForm?.createSubcategoryAttributes} onChange={handleOnChangeAttributesSubcategory} showArrow name="createSubcategoryAttributes" placeholder="Izaberite atribute" type="text" id="createSubcategoryAttributes" style={{ width: '100%' }}>
            {attributes &&
              attributes.length > 0 &&
              attributes.map((item) => {
                return (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </div>

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="seoTitle">
            <b>Seo title:</b>
          </label>

          <input name="seoTitle" id="seoTitle" value={createSubcategoryForm.seoTitle || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Seo title" data-cy="google-description-create-subcategory-input" className="input" />
        </div>

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createSubcategoryGoogleDescription">
            <b>Google opis:</b>
          </label>

          <input name="createSubcategoryGoogleDescription" id="createSubcategoryGoogleDescription" value={createSubcategoryForm.createSubcategoryGoogleDescription || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Google opis" data-cy="google-description-create-subcategory-input" className="input" />
        </div>

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createSubcategoryKeywords">
            <b>Ključne reči:</b>
          </label>

          <input name="createSubcategoryKeywords" id="createSubcategoryKeywords" value={createSubcategoryForm.createSubcategoryKeywords || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Ključne reči" data-cy="keywords-create-subcategory-input" className="input" />
        </div>

        <div className="form-group">
          <label className="label mb-1 d-block" htmlFor="createKeywords">
            <b>Opis:</b>
          </label>
          <div className="select select-result">
            <Editor
              apiKey={apiKey}
              name="description"
              onEditorChange={(newValue, editor) => {
                getTextValue(editor.getContent());
                handleOnChange({ target: { name: 'descriptionSub', value: editor.getContent() } });
              }}
              init={{
                height: 600,
                menubar: true,
                entity_encoding: 'raw',
                plugins: 'table link',
              }}
              value={createSubcategoryForm.description}
            />
          </div>
        </div>

        <div className="form-group form-group-sidebar">
          <button data-cy="modal_create_cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleSubcategoryCreate(false)}>
            <span className="text">Otkaži</span>
          </button>

          {!createSubBtnLoader ? (
            <button data-cy="modal_create_submit-btn" type="submit" className="btn btn-primary" disabled={disabledCreateSub}>
              <span className="text">Sačuvaj podkategoriju</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">Čuvanje ...</span>
            </button>
          )}
        </div>
      </form>
    </SidebarModal>
  );
};
