import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';
import { Categories } from './categories/Categories';
import { Description } from './description/Description';
import { Eligible } from './eligible/Eligible';
import { Specification } from './specification/Specification';
import { Select } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../../../../config/index';
import { useAuth } from '../../../../../context/useAuth';
import { PERMISSIONS } from '../../../../../utils/permissions';

export const ProductInfo = (props) => {
  const { user } = useAuth();
  const { form, setForm, onChange, formError } = useProducts();
  const {
    className,
    categoriesProps: { selectedSubCategory },
  } = props;
  const [brands, setBrands] = useState();
  const [declarations, setDeclarations] = useState();
  const { token } = user;

  const fetchBrands = async () => {
    const res = await axios.post(
      `${SERVER_URL}/brands`,
      { department: 'grocery' },
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setBrands(res.data.items);
  };

  const fetchDeclarations = async () => {
    const res = await axios.get(`${SERVER_URL}/declarations`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    setDeclarations(res.data.data);
  };

  useEffect(() => {
    fetchBrands();
    fetchDeclarations();

    if (form && form.brand && form.brand._id) {
      form.brand = form.brand._id;
    }
  }, []);

  return (
    <Wrapper className={`product__card ${className}`}>
      <div className="wrapper-element p-0">
        <header className="card-header">
          <h4>Informacije o proizvodu</h4>
        </header>

        <main className="card-content relative">
          <form name="product-info" className="form">
            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>Naziv proizvoda:</b>
              </label>
              <input value={form.productName || ''} onChange={onChange} name="productName" placeholder="Naziv proizvoda" type="text" id="productName" className={`input ${formError.productName ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.productName ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="productSlug">
                <b>Slug:</b>
              </label>

              <input value={form.productSlug || ''} onChange={onChange} name="productSlug" placeholder="Slug proizvoda" type="text" id="productSlug" className={`input ${formError.productSlug ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.productSlug ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="productCode">
                <b>Šifra proizvoda:</b>
              </label>

              <input value={form.productCode || ''} onChange={onChange} name="productCode" placeholder="Šifra proizvoda" type="text" id="productCode" className={`input ${formError.productCode ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.productCode ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="seoTitle">
                <b>Seo naslov:</b>
              </label>
              <input value={form.seoTitle || ''} onChange={onChange} name="seoTitle" placeholder="Seo naslov" type="text" id="seoTitle" className={`input ${formError.seoTitle ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.seoTitle ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="seoDescription">
                <b>Seo opis:</b>
              </label>
              <input value={form.seoDescription || ''} onChange={onChange} name="seoDescription" placeholder="Seo opis" type="text" id="seoDescription" className={`input ${formError.seoDescription ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.seoDescription ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="seoKeywords">
                <b>Seo ključne reči (keywords):</b>
              </label>
              <input value={form.seoKeywords || ''} onChange={onChange} name="seoKeywords" placeholder="Seo opis" type="text" id="seoKeywords" className={`input ${formError.seoKeywords ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.seoKeywords ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="brand">
                <b>Brend:</b>
              </label>

              <Select showSearch optionFilterProp="children" value={form.brand} onChange={onChange} name="brand" placeholder="Brend proizvoda" type="text" id="brand" style={{ width: '100%' }}>
                {brands &&
                  brands.length > 0 &&
                  brands.map((item, index) => {
                    return <Option value={item._id}>{item.name}</Option>;
                  })}
              </Select>
              <p data-cy="" className={`error__onblur ${formError.brand ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="declaration">
                <b>Deklaracija:</b>
              </label>

              <Select
                showSearch
                optionFilterProp="children"
                value={form.declaration}
                onChange={(e) => {
                  onChange(e, 'deklaracija');
                }}
                name="declaration"
                placeholder="Deklaracija proizvoda"
                type="text"
                id="declaration"
                style={{ width: '100%' }}
              >
                {declarations &&
                  declarations.length > 0 &&
                  declarations.map((item, index) => {
                    return <Option value={item._id}>{item.name}</Option>;
                  })}
              </Select>
            </div>

            <div className="form-group">
              <span className="label">
                <b>Vidljivost:</b>
              </span>

              <div className="checkbox-group">
                <div className="item">
                  <input checked={form.productFeatured || false} onChange={onChange} name="productFeatured" type="checkbox" id="productFeatured" className="checkbox" />
                  <label className="label" htmlFor="productFeatured">
                    <b>Proizvod istaknut na naslovnoj</b>
                  </label>
                </div>

                <div className="item">
                  <input checked={form.productVisible || false} onChange={onChange} name="productVisible" type="checkbox" id="productVisible" className="checkbox" />
                  <label className="label" htmlFor="productVisible">
                    <b>Vidljivo u prodavnici</b>
                  </label>
                </div>

                <div className="item">
                  <input checked={form.productFeaturedSubcategory || false} onChange={onChange} name="productFeaturedSubcategory" type="checkbox" id="productFeaturedSubcategory" className="checkbox" />
                  <label className="label" htmlFor="productFeaturedSubcategory">
                    <b>Proizvod istaknut u podkategoriji</b>
                  </label>
                </div>
              </div>
            </div>

            {/* <div className="form-group">
              <Eligible label="Kvalifikovan" className="product-eligible" />
            </div> */}

            <div className="form-group">
              <Categories label="Kategorije proizvoda" className="product-categories" categoriesProps={{ ...props.categoriesProps }} />
            </div>

            <div className="form-group">
              <label className="label" htmlFor="unit">
                <b>Jedinica mere:</b>
              </label>
              <input value={form.unit || ''} onChange={onChange} name="unit" placeholder="Jedinica mere" type="text" id="unit" className={`input ${formError.unit ? 'error' : ''}`} />
            </div>

            <div className="form-group">
              <Description label="Opis proizvoda" className="product-description" />
            </div>

            {/* <div className="form-group">
              <Specification label="Specifikacija proizvoda" className="product-description" />
            </div> */}
          </form>
        </main>
      </div>
    </Wrapper>
  );
};
