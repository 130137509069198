import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { Wrapper } from '../../../../../components/content';
import { Select } from 'antd';
import { useProducts } from '../../../../../context/products';

const { Option } = Select;

const InnerTab = ({ item, setAttributesWithUnits }) => {
  const { setForm } = useProducts();
  const units = item.units;
  const handleOnChangeUnits = (event) => {
    const value = event;

    const selectedObjects = units.filter((unit) => value.includes(unit._id));

    setAttributesWithUnits((prevState) => {
      return prevState.map((item) => {
        if (selectedObjects?.map((obj) => obj.attribute).includes(item.Attribute)) {
          return { ...item, values: selectedObjects.map((obj) => obj._id) };
        }
        return item;
      });
    });
  };

  const handleDeselect = (value) => {
    setAttributesWithUnits((prevState) => {
      return prevState.map((item) => {
        if (item.values.includes(value)) {
          return { ...item, values: item.values.filter((val) => val !== value) };
        }
        return item;
      });
    });
  };

  return (
    <div className="form-group form-group-selection-items">
      <Select mode="multiple" defaultValue={[]} onChange={handleOnChangeUnits} onDeselect={handleDeselect} showArrow name="updateSubcategoryAttributes" placeholder="Izaberite vrednosti atributa" type="text" id="updateSubcategoryAttributes" style={{ width: '100%' }}>
        {units &&
          units.length > 0 &&
          units.map((item) => {
            return (
              <Option key={item._id} value={item._id}>
                {item.value}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};

export const ProductAttributes = ({ attributesOptions, selectedSubCategory }) => {
  const { setForm } = useProducts();
  const [attributesWithUnits, setAttributesWithUnits] = useState([]);
  useEffect(() => {
    setAttributesWithUnits(
      attributesOptions.map((item) => {
        return { Attribute: item._id, values: [] };
      })
    );
  }, [selectedSubCategory, attributesOptions]);

  useEffect(() => {
    setForm((prevState) => {
      return { ...prevState, productAttributesUnit: attributesWithUnits };
    });
  }, [attributesWithUnits]);

  return (
    <Wrapper className={`product__card product__card--tags`}>
      <header className="card-header">
        <h4>Atributi Proizvoda</h4>
      </header>
      <main className="card-content">
        {attributesOptions && attributesOptions.length > 0 && (
          <div className="form-group form-group-selection-items">
            <Tabs
              defaultActiveKey="1"
              type="card"
              size="large"
              items={attributesOptions.map((item, i) => {
                return {
                  label: `${item.name}`,
                  key: i,
                  children: <InnerTab item={item} setAttributesWithUnits={setAttributesWithUnits} />,
                };
              })}
            />
          </div>
        )}
        {selectedSubCategory && !attributesOptions.length && <p>Nema atributa za izabranu podkategoriju.</p>}
        {!selectedSubCategory && !attributesOptions.length && <p>Izaberite podkategoriju proizvoda.</p>}
      </main>
    </Wrapper>
  );
};
