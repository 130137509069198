import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { Link } from 'react-router-dom';

const Table = (props) => {
  // TABLE
  const { data = [], handleCheckedRows, handleDisabledButton, handleToggle } = props;

  // PAGINATION
  const { pagination } = props;
  // CUSTOM FOR ACTIONS
  const { updateActions, statusActions, deleteActions, location, groceryPermissions, getGroceryPermissions } = props;
  // CUSTOM FOR FILTERS
  const { handleStatusFilter } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleDisabledButton(isCheckAll);
  }, [isCheckAll, handleDisabledButton]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [isCheck, handleCheckedRows]);

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Email</th>
            <th>Datum/Vreme</th>
            <th>Akcija</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data?.length > 0 ? (
            data?.map((item) => {
              const dateCreated = (item?.createdAt && new Date(item?.createdAt).toLocaleDateString('sr-RS', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })) || 'N/A';
              return (
                <tr key={item._id}>
                  <td>{item.email}</td>
                  <td>{dateCreated}</td>

                  <td className="y-top">
                    {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteBanner) && (
                      <button className="btn btn-actions" style={{ marginLeft: '15px', border: '1px solid #D9D9D9', borderRadius: '5px', height: '30px', width: '30px' }} type="button" data-cy="actions-delete-brand" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
                        <span className="icon">
                          <img alt="Delete" src="/assets/icons/delete.svg" />
                        </span>
                        <span className="text">
                          <strong></strong>
                        </span>
                      </button>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data && data?.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
