import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useAuth } from '../../../context/useAuth';
import { Section, Main } from '../../../components/content';
import { SERVER_URL } from '../../../config/index';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import Table from './Table';
import Loading from '../../../components/loading/Loading';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import { Link, useLocation } from 'react-router-dom';
import { PERMISSIONS } from '../../../utils/permissions';
import { getGroceryPermissions } from '../../../middleware';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { notification } from 'antd';
import { UpdateOrderStatus } from './update-order-status/UpdateOrderStatus';
import { RescheduleOrder } from './reschedule-order/RescheduleOrder';
import { DeleteReschedule } from './delete-reschedule/DeleteReschedule';

const options = [5, 10, 20, 50, 100];
const statuses = ['Ordered', 'U toku', 'Dostava u toku', 'Dostavljeno', 'Otkazano'];

const Orders = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [location, user]);

  // LOADER PROTECTION
  const [updateStatusBtnLoader, setUpdateStatusBtnLoader] = useState(false);

  // SEARCH
  const [search, setSearch] = useState(sessionStorage.getItem('orders_search') ? sessionStorage.getItem('orders_search') : null);
  const [searchForm, setSearchForm] = useState(sessionStorage.getItem('orders_searchForm') ? { query: sessionStorage.getItem('orders_searchForm') } : { query: '' });
  const [filterStatus, setFilterStatus] = useState('');

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    sessionStorage.setItem('orders_search', searchForm.query);

    setReadDataRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    sessionStorage.setItem('orders_searchForm', value);
    sessionStorage.removeItem('orders_page');

    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    sessionStorage.removeItem('orders_page');
    sessionStorage.removeItem('orders_search');
    sessionStorage.removeItem('orders_searchForm');
    setSearchForm({ query: '' });
    setReadDataRefetch((prevState) => !prevState);
  };

  // PAGE
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('orders_page') ? parseFloat(sessionStorage.getItem('orders_page')) : 1);
  const [limitPage, setLimitPage] = useState(10);

  // FILTER STATUS
  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setReadDataRefetch((prevState) => !prevState);
  };

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(() => {
    const { token } = user;

    try {
      const response = axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.orders}/${currentPage}/${limitPage}/${search || null}/?status=${filterStatus}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);
          return res;
        })
        .catch((err) => {
          setReadDataLoading(false);
          console.error(err);
        });

      return response;
    } catch (err) {}
  }, [user, limitPage, search, currentPage, filterStatus]);

  const handleDelete = (e) => {
    try {
      const { token } = user;
      const response = axios.delete(`${SERVER_URL}/orders/delete-order/${e.id}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

      notification.success({
        message: 'Uspešno obrisana narudžbenica',
      });

      setTimeout(() => {
        window.location.reload();
      }, 400);
    } catch (error) {
      console.log(error);
      notification.error({
        message: error,
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(sessionStorage.getItem('orders_page') ? parseFloat(sessionStorage.getItem('orders_page')) : 1);
    }
  }, [search, setCurrentPage]);

  useEffect(() => {
    setCurrentPage(sessionStorage.getItem('orders_page') ? parseFloat(sessionStorage.getItem('orders_page')) : 1);
  }, [limitPage, setCurrentPage]);

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    page: 'orders',
    pagesCount: readData.data?.pagesCount,
    totalCount: readData.data?.totalCount,
  };

  // FILTER PROPS
  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter,
  };

  // RESCHEDULE ORDER
  const rescheduledModalFormRef = useRef();
  const [toggleRescheduled, setToggleRescheduled] = useState(false);
  const [rescheduledOrder, setRescheduledOrder] = useState({ id: '', rescheduled: '', status: '', moment: '' });

  //DELETE-RESCHEDULED
  const deleteRescheduledModalFormRef = useRef();
  const [toggleDeleteRescheduled, setToggleDeleteRescheduled] = useState(false);
  // const [deleteRescheduledOrderId, setDeleteRescheduledOrderId] = useState('');

  // DELETE-RESCHEDULE PROPS
  const deleteRescheduledProps = {
    active: toggleDeleteRescheduled,
    onToggle: setToggleDeleteRescheduled,
    formRef: deleteRescheduledModalFormRef,
    onSetDeleteReschedule: setRescheduledOrder,
    getDeleteReschedule: rescheduledOrder,
  };

  const onSelectDate = (moment, stringTime, id, status) => {
    setRescheduledOrder({ id: id, rescheduled: stringTime, status: status, moment: moment });
  };
  // RESCHEDULE PROPS
  const rescheduledProps = {
    active: toggleRescheduled,
    onToggle: setToggleRescheduled,
    formRef: rescheduledModalFormRef,
    onSetReschedule: setRescheduledOrder,
    getReschedule: rescheduledOrder,
  };

  // UPDATE STATUS
  const updateModalFormRef = useRef();
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateId, setUpdateId] = useState({ id: '', status: '', canceled_reason: '', rescheduled: '' });

  // UPDATE PROPS
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    onSetId: setUpdateId,
    getId: updateId,
  };

  // TABLE ACTIONS PROPS
  const tableActionsProps = {
    handleToggle: handleToggle,
    updateActions: updateProps,
    rescheduledActions: rescheduledProps,
    deleteRescheduledActions: deleteRescheduledProps,
    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  function handleToggle(props) {
    const { name, state, data } = props;
    switch (name) {
      case 'update':
        state.onToggle(true);
        state.onSetId({ id: data.id, status: data.status, canceled_reason: data.canceled_reason, rescheduled: data.rescheduled });
        break;
      case 'rescheduled':
        state.onToggle(true);
        state.onSetReschedule({ id: data.id, status: data.status });
        break;
      case 'delete-rescheduled':
        state.onToggle(true);
        state.onSetDeleteReschedule({ id: data.id, rescheduled: '', status: data.status });
        //
        break;
      default:
        console.warn('Default of: handleToggle function');
    }
  }

  // STATUS
  const [selectedStatus, setSelectedStatus] = useState('');
  const [cancelationReason, setCancelationReason] = useState('');
  let radioArray = [];
  if (updateId.status !== '') {
    radioArray = statuses.filter((item) => item !== updateId.status);
  }
  const onStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setCancelationReason('');
  };
  async function handleOnSubmit({ event }) {
    event.preventDefault();
    const { token } = user;
    let updatePayload = {
      status: selectedStatus,
      canceled_reason: cancelationReason,
      rescheduled: updateId.rescheduled,
    };
    try {
      if (selectedStatus === 'Canceled' && cancelationReason === '') {
        notification.error({
          message: 'Please enter a reason for order cancelation.',
          placement: 'bottomLeft',
        });
      } else if (selectedStatus === '') {
        notification.error({
          message: 'Please select a status or click cancel.',
          placement: 'bottomLeft',
        });
      } else {
        setUpdateStatusBtnLoader(true);
        axios
          .put(`${SERVER_URL}/${AXIOS_API_CALL.orders}/${AXIOS_API_CALL.updateOrder}/${updateId.id}`, { ...updatePayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
          .then((res) => {
            if (res.status === 200) {
              setToggleUpdate(false);
              setReadDataRefetch((prevState) => !prevState);
              notification.success({
                message: res.data.message,
                placement: 'bottomLeft',
              });
            }
            setTimeout(() => {
              setUpdateStatusBtnLoader(false);
            }, 400);
          })
          .catch((err) => console.error(err))
          .finally(setTimeout(() => {}, 700));
      }
    } catch (err) {}
  }
  async function handleOnSubmitRescheduled({ event }) {
    event.preventDefault();
    const { token } = user;
    try {
      setUpdateStatusBtnLoader(true);
      axios
        .put(`${SERVER_URL}/${AXIOS_API_CALL.orders}/${AXIOS_API_CALL.updateOrder}/${rescheduledOrder.id}`, { status: rescheduledOrder.status, rescheduled: rescheduledOrder.rescheduled === '' ? '' : rescheduledOrder.rescheduled }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.status === 200) {
            setToggleRescheduled(false);
            setToggleDeleteRescheduled(false);
            setReadDataRefetch((prevState) => !prevState);
            notification.success({
              message: res.data.message,
              placement: 'bottomLeft',
            });
          }
          setTimeout(() => {
            setUpdateStatusBtnLoader(false);
          }, 400);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error('error', err);
    }
  }

  useEffect(() => {
    if (toggleUpdate === false) {
      setSelectedStatus('');
      setCancelationReason('');
    }
  }, [toggleUpdate]);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current - 1 < Date.now();
  }

  const updateOrderStatusProps = { updateId, updateProps, handleOnSubmit, updateModalFormRef, onStatusChange, selectedStatus, setCancelationReason, updateStatusBtnLoader, setToggleUpdate, radioArray };
  const rescheduleOrderProps = { rescheduledProps, rescheduledModalFormRef, handleOnSubmitRescheduled, rescheduledOrder, updateStatusBtnLoader, setToggleRescheduled, disabledDate };
  const deleteRescheduleProps = { deleteRescheduledProps, deleteRescheduledModalFormRef, handleOnSubmitRescheduled, updateStatusBtnLoader, setToggleDeleteRescheduled };

  return (
    <>
      <div className="page-title orders">
        Narudžbine
        {/* <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`}>Kreiraj narudžbinu</Link> */}
      </div>
      <div className="page-title orders d-flex">
        <p className="mr-4">Ukupno dostavljenih: {readData && readData.data && readData.data.delivered ? `${parseFloat(readData.data.delivered).toFixed(2)} %` : '0 %'} </p>
        <p>Ukupno otkazanih: {readData && readData.data && readData.data.canceled ? `${parseFloat(readData.data.canceled).toFixed(2)} %` : '0 %'} </p>
      </div>
      <Section className="section__wrapper section__orders">
        <header className="section__header">
          <div className="title">Narudžbina</div>
          {!readDataLoading ? (
            <div className="filter">
              <span style={{ fontSize: '18px', lineHeight: '24px', color: 'rgb(99, 99, 99)' }}>Prikaži: </span>
              <SelectCustom options={options} label={'Stavki'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}
          {!readDataLoading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" />
                  </span>
                  <input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Pretraži" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Pretraži
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}
        </header>
        {/* Main Content */}
        <Main className="section__content section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table handleDelete={handleDelete} data={readData.data?.Data} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>

        {/* Update Order status */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateOrders) && <UpdateOrderStatus {...updateOrderStatusProps} />}
        {/* Reschedule Order */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateOrders) && <RescheduleOrder {...rescheduleOrderProps} />}

        {/* Delete Reschedule */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateOrders) && <DeleteReschedule {...deleteRescheduleProps} />}
      </Section>
    </>
  );
};

export default Orders;
