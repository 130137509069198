import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { Link } from 'react-router-dom';

const Table = (props) => {
  // TABLE
  const { data = [], handleCheckedRows, handleDisabledButton, handleToggle } = props;

  // PAGINATION
  const { pagination } = props;
  // CUSTOM FOR ACTIONS
  const { updateActions, statusActions, deleteActions, location, groceryPermissions, getGroceryPermissions } = props;
  // CUSTOM FOR FILTERS
  const { handleStatusFilter } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // HANDLE CHECKED ALL
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // HANDLE CHECKED CURRENT
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckAll(true);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleDisabledButton(isCheckAll);
  }, [isCheckAll, handleDisabledButton]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [isCheck, handleCheckedRows]);

  const handleUpdateClick = async (brandId) => {
    try {
      setLoadingBrand(true);

      const brandDataResponse = await axios.get(`${SERVER_URL}/brands/${brandId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const brandData = brandDataResponse.data;

      setUpdateBrand(brandData);

      handleToggle({ name: 'update', state: updateActions, data: brandId });

      setLoadingBrand(false);
    } catch (err) {
      setLoadingBrand(false);
      console.error(err);
    }
  };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            {/* <th>Slika</th> */}
            <th>Naslov</th>
            {/* <th>URL</th> */}
            <th>Opis</th>
            <th>Kreirao</th>
            <th>Datum/Vreme</th>
            <th>Akcija</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data?.length > 0 ? (
            data?.map((item) => {
              const truncatedTitle = item.title.length > 20 ? `${item.title.substring(0, 20)}...` : item.title;
              const truncatedContent = item.content;
              const truncatedUrl = item.url.length > 30 ? `${item.url.substring(0, 30).replace(/(<([^>]+)>)/gi, '')}...` : item.url;
              const featuredImage = item.image && item.image.length > 0 ? item?.image[0]?.url : '/assets/icons/image-line.svg';
              const createdBy = (item?.createdBy && item?.createdBy?.length > 0 && `${item?.createdBy[0]?.firstName} ${item?.createdBy[0]?.lastName}`) || 'N/A';
              const dateCreated = (item?.createdAt && new Date(item?.createdAt).toLocaleDateString('sr-RS', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })) || 'N/A';
              return (
                <tr key={item._id}>
                  {/* <td>
                    {item && item.image && item.image.length > 0 ? (
                      <img
                        className="img"
                        src={item.image[0].url}
                        alt={item.title}
                        width={100}
                        height={60}
                        style={{
                          borderRadius: '0.25rem',
                          objectFit: 'contain',
                          objectPosition: 'center',
                        }}
                      />
                    ) : (
                      <img
                        className="img"
                        src="/assets/images/product-placeholder.svg"
                        alt={item.title}
                        width={100}
                        height={60}
                        style={{
                          borderRadius: '0.25rem',
                        }}
                      />
                    )}
                  </td> */}
                  <td>{truncatedTitle}</td>
                  {/* <td>{truncatedUrl}</td> */}
                  <td>{truncatedContent}</td>
                  <td>{createdBy}</td>
                  <td>{dateCreated}</td>

                  <td className="y-top">
                    {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateBlog) && (
                      <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateBlog}/${item._id}`} className="link btn-actions link-details">
                        <button className="btn btn-actions" style={{ border: '1px solid #D9D9D9', borderRadius: '5px', height: '30px', width: '30px' }} type="button" data-cy="actions-update-attributes">
                          <span className="icon">
                            <img alt="Update" src="/assets/icons/edit-silver.svg" />
                          </span>
                          <span className="text">
                            <strong></strong>
                          </span>
                        </button>
                      </Link>
                    )}
                    {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteBlog) && (
                      <button className="btn btn-actions" style={{ marginLeft: '15px', border: '1px solid #D9D9D9', borderRadius: '5px', height: '30px', width: '30px' }} type="button" data-cy="actions-delete-brand" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
                        <span className="icon">
                          <img alt="Delete" src="/assets/icons/delete.svg" />
                        </span>
                        <span className="text">
                          <strong></strong>
                        </span>
                      </button>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data && data?.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
