import React, { useEffect, useState } from 'react';
import SortableTree, { getTreeFromFlatData } from '@nosferatu500/react-sortable-tree';
import { useProducts } from '../../../../../../context/products';
import { Select } from 'antd';

export const Categories = (props) => {
  const { productCategoriesData, onChange, formError, form, productEdit } = useProducts();
  const {
    label,
    className,
    categoriesProps: { selectedCategory, setSelectedCategory, selectedSubCategory, setSelectedSubCategory },
  } = props;

  const [treeData, setTreeData] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [subcategories, setSubCategories] = useState([]);

  useEffect(() => {
    handleData();
  }, [treeData]);

  useEffect(() => {
    let data = [];

    data = getTreeFromFlatData({
      flatData: productCategoriesData.map((node) => ({
        ...node,
        expanded: true,
      })),
      getKey: (node) => node._id,
      getParentKey: (node) => node.parent,
      rootKey: null,
    });

    for (let i = 0; i < data.length; i++) {
      data[i].disabled = true;
    }

    setTreeData(data);
  }, [productCategoriesData]);

  const handleData = () => {
    if (form && form.productCategory) {
      setSelectedCategory(form.productCategory);

      for (let i = 0; i < treeData.length; i++) {
        if (treeData[i]._id.toString() === form.productCategory.toString()) {
          setSubCategories(treeData[i].children);
        }
      }

      if (form.productSubCategory) {
        setSelectedSubCategory(form.productSubCategory);
      }
    }
  };

  const handleChangeCategory = (e) => {
    setSelectedCategory(e);
    setSelectedSubCategory(null);
    onChange(null, 'potkategorija');
    onChange(e, 'kategorijaEdit');
    for (let i = 0; i < treeData.length; i++) {
      if (treeData[i]._id.toString() === e.toString()) {
        setSubCategories(treeData[i].children);
      }
    }
  };
  return (
    <div className={`custom-select ${className !== undefined ? className : ''} ${formError.productCategory ? 'error' : ''}`}>
      <div className="select-result">
        <div style={{ marginBottom: '20px' }} className="select-result category-result">
          <p>Kategorija:</p>
          <Select
            showSearch
            optionFilterProp="children"
            onChange={(e) => {
              handleChangeCategory(e);
            }}
            value={selectedCategory}
          >
            {treeData &&
              treeData.length > 0 &&
              treeData.map((item, index) => {
                return (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </div>
        <div className="select-result category-result">
          <p>Potkategorija:</p>
          <Select
            showSearch
            optionFilterProp="children"
            onChange={(e) => {
              onChange(e, 'kategorija');
              onChange(e, 'potkategorija');
              setSelectedSubCategory(e);
            }}
            value={selectedSubCategory}
            disabled={selectedCategory ? false : true}
          >
            {subcategories &&
              subcategories.length > 0 &&
              subcategories.map((item, index) => {
                return (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>

      <p data-cy="" className={`error__onblur ${formError.productSubCategory ? 'error' : ''}`}>
        * Ovo polje je obavezno!
      </p>
    </div>
  );
};
