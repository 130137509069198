import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';
import { AXIOS_API_CALL } from '../../utils/endpoint';
import { PERMISSIONS } from '../../utils/permissions';
import { generateId, slugify, slugifyReplaceAll } from '../../helpers/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

const ReferencesContext = createContext(null);

const ReferencesProvider = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [tabs, setTabs] = useState(0);
  const [refetchGallery, setRefetchGallery] = useState(false);
  const [tabsGallery, setTabsGallery] = useState(0);
  const [productDropdownOptions, setProductDropdownOptions] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);

  const convertToCleanUrl = (input) => {
    const charMap = {
      š: 's',
      đ: 'dj',
      č: 'c',
      ć: 'c',
      ž: 'z',
      Š: 's',
      Đ: 'dj',
      Č: 'c',
      Ć: 'c',
      Ž: 'z',
    };

    return input
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .toLowerCase()
      .replace(/[šđčćžŠĐČĆŽ]/g, (match) => charMap[match] || match)
      .replace(/[^a-z0-9-]/g, '') // Keep lowercase letters, numbers, and hyphens
      .replace(/-+/g, '-'); // Remove consecutive hyphens
  };

  const [elements, setElements] = useState([]);
  const [imageRemoved, setImageRemoved] = useState(false);

  function handleOnRemoveUpload(props) {
    const { name } = props;

    if (name === 'create') {
      setProductData((prevData) => ({
        ...prevData,
        image: null,
      }));
      setImageData({
        ...imageData,
        upload: null,
        image: null,
        blob: null,
      });
      setCreateFormError({
        ...createFormError,
        imageSize: false,
        imageType: false,
      });
      setImageRemoved(true);
    }
    if (name === 'update') {
      setUpdateBrand((prevData) => ({
        ...prevData,
        image: null,
      }));
      setImageUpdateData({
        ...imageUpdateData,
        upload: null,
        blob: null,
      });
    }

    if (name === 'updateRemove') {
      setUpdateBrand((prevForm) => ({
        ...prevForm,
        image: null,
      }));
    }
  }

  // Form
  const [form, setForm] = useState({
    blogTitle: '',
    description: '',
    googleDescription: '',
    keywords: '',
    blogUrl: '',
    blogCategories: [],
    blogProducts: [],
    brand: null,
    productVisible: false,
    productFeatured: false,
    productEligible: [],
    productCategory: '',
    productSpecification: '',
    productDeclaration: '',
    productGallery: [],
    productWeight: '',
    productWeightVariant: '',
    productRegularPrice: '',
    productRegularPriceVariant: '',
    productSalePrice: '',
    productSalePriceVariant: '',
    productVariations: [],
    productSku: '',
    productTags: [],
    productAttributesUnit: [],
    productType: 'simple',
    productVariation: 'none',
    productManage: false,
    productStockQuantity: '',
    productUpcCode: '',
  });

  const [formError, setFormError] = useState({
    blogTitle: null,
    brand: null,
    blogUrl: null,
    keywords: null,
    googleDescription: null,
    productCategory: null,
    description: null,
    productWeight: { empty: null, format: null },
    productRegularPrice: { empty: null, format: null },
    productSalePrice: { bigger: null, format: null },
    productRegularPriceVariant: { empty: null, format: null },
    productWeightVariant: { empty: null, format: null },
    productVariations: [],
  });

  const formBase = {
    _id: generateId(),
    variantImage: {
      _id: null,
      __v: 0,
      createdAt: '',
      updatedAt: '',
      forMobile: false,
      localPath: '',
      originalName: '',
      url: '',
    },
    variantSku: '',
    variantRegularPrice: '',
    variantSalePrice: '',
    variantQuantity: '',
    variantWeight: '',
    variantType: 'generated',
    units: [],
  };

  const generateNewFormBase = () => {
    return { ...formBase, _id: generateId() };
  };

  const [formFields, setFormFields] = useState([]);

  // On Change
  const onChange = (event, type) => {
    if (event && event.target) {
      const { name, value, checked } = event.target;
      setForm((prevState) => {
        if (name === 'productRegularPrice' || name === 'productSalePrice' || name === 'productRegularPriceVariant' || name === 'productWeightVariant') {
          const priceValue = value
            .replace(/[^\d\.]/g, '')
            .replace(/\./, 'x')
            .replace(/\./g, '')
            .replace(/x/, '.');
          return { ...prevState, [name]: priceValue };
        }
        if (name === 'productStockQuantity') {
          return { ...prevState, [name]: value.replace(/[^0-9]/g, '') };
        }

        if (value === 'on') {
          return { ...prevState, [name]: checked };
        } else {
          if (name === 'blogTitle') {
            const slug = convertToCleanUrl(value);
            return { ...prevState, [name]: value, blogUrl: slug };
          } else if (name === 'blogUrl') {
            const slug = convertToCleanUrl(value);
            return { ...prevState, [name]: slug };
          } else {
            return { ...prevState, [name]: value };
          }
        }
      });
    } else {
      if (type && type === 'kategorija') {
        setForm((prevState) => {
          return { ...prevState, category: event };
        });
      } else if (type && type === 'kategorijaEdit') {
        setForm((prevState) => {
          return { ...prevState, productCategory: event };
        });
      } else if (type && type === 'potkategorija') {
        setForm((prevState) => {
          return { ...prevState, productSubCategory: event };
        });
      } else if (type && type === 'deklaracija') {
        setForm((prevState) => {
          return { ...prevState, declaration: event };
        });
      } else {
        setForm((prevState) => {
          return { ...prevState, brand: event };
        });
      }
    }
  };

  // const handleChange = (e) => {
  //   const regex = /^[0-9\b]+$/;
  //   if (e.target.value === "" || regex.test(e.target.value)) {
  //     setVal(e.target.value);
  //   }
  // };

  // On Valid
  const onValid = useCallback(
    (form) => {
      let checkIsValid = false;

      // validate input fields for simple product
      let checkBlogTitle = false;
      let checkBlogUrl = false;
      // let checkDescription = false;
      let checkKeywords = false;
      let checkGoogleDescription = false;
      let checkContent = false;

      if (form.blogTitle.length > 0) {
        checkBlogTitle = true;
        setFormError((prevState) => {
          return { ...prevState, blogTitle: false };
        });
      } else {
        checkBlogTitle = false;
        setFormError((prevState) => {
          return { ...prevState, blogTitle: true };
        });
      }

      if (form.keywords.length > 0) {
        checkKeywords = true;
        setFormError((prevState) => {
          return { ...prevState, keywords: false };
        });
      } else {
        checkKeywords = false;
        setFormError((prevState) => {
          return { ...prevState, keywords: true };
        });
      }

      if (form.googleDescription.length > 0) {
        checkGoogleDescription = true;
        setFormError((prevState) => {
          return { ...prevState, googleDescription: false };
        });
      } else {
        checkGoogleDescription = false;
        setFormError((prevState) => {
          return { ...prevState, googleDescription: true };
        });
      }

      if (form.blogUrl.length > 0) {
        checkBlogUrl = true;
        setFormError((prevState) => {
          return { ...prevState, blogUrl: false };
        });
      } else {
        checkBlogUrl = false;
        setFormError((prevState) => {
          return { ...prevState, blogUrl: true };
        });
      }

      if (form.description.length > 0) {
        checkContent = true;
        setFormError((prevState) => {
          return { ...prevState, description: false };
        });
      } else {
        checkContent = false;
        setFormError((prevState) => {
          return { ...prevState, description: true };
        });
      }

      if (checkBlogTitle && checkBlogUrl && checkKeywords && checkGoogleDescription && checkContent) {
        checkIsValid = true;
      }
      return checkIsValid;
    },
    [formError, form]
  );

  // On Cancel
  const onCancel = () => {
    window.location.replace('/admin/grocery/references');
  };

  const [savedForm, setSavedForm] = useState({});

  useEffect(() => {
    setSavedForm(form);
  }, [form]);

  // ----------------------- IMAGE ----------------------
  const [imageData, setImageData] = useState({
    upload: [],
    image: [],
    blob: '',
  });

  // ------------------ ERROR HANDLING ------------------
  const [createFormError, setCreateFormError] = useState({
    imageSize: false,
    imageType: false,
  }); // check

  const [updateFormError, setUpdateFormError] = useState({
    imageSize: false,
    imageType: false,
  }); // check

  function handleOnUpload(props) {
    const { name, img } = props;

    const isJpgOrPng = img.type === 'image/jpeg' || img.type === 'image/jpg' || img.type === 'image/png';
    const isLt2M = img.size / 1024 / 1024 < 2;

    if (name === 'createImage') {
      if (isJpgOrPng && isLt2M) {
        setImageData({
          ...imageData,
          upload: img,
          blob: URL.createObjectURL(img),
        });
        setCreateFormError({
          ...createFormError,
          imageType: false,
          imageSize: false,
        });
      }

      if (!isJpgOrPng) {
        setCreateFormError({ ...createFormError, imageType: true });
      }

      if (!isLt2M) {
        setCreateFormError({ ...createFormError, imageSize: true });
      }
    }

    if (name === 'updateImage') {
      if (isJpgOrPng && isLt2M) {
        setImageData({
          ...imageData,
          upload: img,
          blob: URL.createObjectURL(img),
        });

        setUpdateFormError({
          ...updateFormError,
          imageType: false,
          imageSize: false,
        });
      }

      if (!isJpgOrPng) {
        setUpdateFormError({ ...updateFormError, imageType: true });
      }

      if (!isLt2M) {
        setUpdateFormError({ ...updateFormError, imageSize: true });
      }
    }
  }

  async function getUploadImage(props) {
    const { name } = props;
    let data = [];

    const { token } = user;

    if (name === 'create') {
      if (imageData.upload) {
        let uploadImage = new FormData();
        uploadImage.append('image', imageData?.upload);
        // return;

        if (imageData?.upload) {
          try {
            await axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`, uploadImage, {
                withCredentials: false,
                headers: {
                  'form-data': 'application/json',
                  uri: 'public/images/dashboard/references/',
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => {
                data = res.data.image;
                setImageData({
                  ...imageData,
                  upload: img,
                  blob: URL.createObjectURL(img),
                });
              })
              .catch((err) => console.error(err))
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    // if (name === 'update') {
    //   if (imageUpdateData.upload.length !== 0) {
    //     let uploadImage = new FormData();
    //     uploadImage.append('image', imageUpdateData?.upload);

    //     if (typeof imageUpdateData?.upload === 'object') {
    //       try {
    //         await axios
    //           .post(`${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`, uploadImage, {
    //             withCredentials: false,
    //             headers: {
    //               'form-data': 'application/json',
    //               uri: 'public/images/dashboard/brands/',
    //               Authorization: `Bearer ${token}`,
    //             },
    //           })
    //           .then((res) => {
    //             data = res.data.image;
    //             setImageUpdateData({
    //               ...imageUpdateData,
    //               image: res.data.image,
    //             });
    //           })
    //           .catch((err) => console.error(err))
    //           .finally(setTimeout(() => {}, 700));
    //       } catch (err) {
    //         console.error(err);
    //       }
    //     }
    //   }
    // }

    return data;
  }

  // On Submit
  const onSubmit = async (path) => {
    const isValidBlog = onValid(form);

    const { token } = user;

    if (isValidBlog) {
      let simplePayload = {
        title: form.blogTitle,
        content: form.description,
        keywords: form.keywords,
        googleDescription: form.googleDescription,
        url: form.blogUrl,
        categories: form.blogCategories,
        recommendedProducts: form.blogProducts,
        createdBy: user.id,
        modifiedBy: user.id,
        images: imagesArray && imagesArray.length > 0 ? imagesArray.map((item, index) => item._id) : [],
        featureImage: imagesArray && imagesArray.length > 0 ? imagesArray[0]._id : null,
      };

      try {
        await axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.createReference}`,
            { ...simplePayload },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              // redirect
              navigate(`/${path}`);

              // notification
              notification.success({
                message: 'Uspešno kreirana referenca.',
                placement: 'bottomLeft',
              });
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {});
      } catch (err) {
        console.error(err);
      }
    }
  };

  // On Submit
  const onUpdate = async (path) => {
    // return;
    const isValidSimpleProduct = onValid(form);

    if (isValidSimpleProduct) {
      let simplePayload = {
        title: form.blogTitle,
        content: form.description,
        keywords: form.keywords,
        googleDescription: form.googleDescription,
        url: form.blogUrl,
        categories: form.blogCategories,
        recommendedProducts: form.blogProducts,
        images: imagesArray && imagesArray.length > 0 ? imagesArray.map((item, index) => item._id) : [],
        featureImage: imagesArray && imagesArray.length > 0 ? imagesArray[0]._id : null,
        // image: getImageUpload ? getImageUpload : null,
        createdBy: user.id,
        modifiedBy: user.id,
      };

      // if (getImageUpload && getImageUpload._id !== null) {
      //   simplePayload = {
      //     ...simplePayload,
      //     image: getImageUpload,
      //   };
      // }

      // if (imageRemoved) {
      //   simplePayload = {
      //     ...simplePayload,
      //     image: null,
      //   };
      // }

      const { token } = user;
      try {
        await axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.updateReference}/${productId}`,
            { ...simplePayload },
            {
              withCredentials: false,
              headers: {
                Authorization: `Bearer ${token}`,
                department: PERMISSIONS.grocery,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              // redirect
              navigate(`/${path}`);

              // notification
              notification.success({
                message: 'Uspešno uredjena referenca.',
                placement: 'bottomLeft',
              });
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {});
      } catch (err) {
        console.error(err);
      }
    }
  };

  const [productData, setProductData] = useState({});
  const [productLoading, setProductLoading] = useState(true);
  const [productRefetch, setProductRefetch] = useState(false);
  const [productEdit, setProductEdit] = useState(false);

  const { id: productId } = useParams();

  const getProduct = useCallback(async () => {
    const { token } = user;

    try {
      setProductLoading(true);
      await axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.getReference}/${productId}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status === 200) {
            const { productGlobalData, variantData } = res.data.message;
            if (productGlobalData) {
              setForm({
                blogTitle: productGlobalData.title,
                description: productGlobalData.content,
                googleDescription: productGlobalData.googleDescription,
                keywords: productGlobalData.keywords,
                blogUrl: productGlobalData.url,
                // image: productGlobalData.image,
                blogCategories: productGlobalData.categories,
                blogProducts: productGlobalData.recommendedProducts,
                blogCreatedBy: productGlobalData.createdBy,
                blogCreatedAt: productGlobalData.createdAt,
              });

              // setImageData({ ...imageData, image: productGlobalData.image, blob: productGlobalData.image.url });
              setImagesArray([...productGlobalData.images]);
            }
            // TODO: Handle feature image
          }
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setProductLoading(false);
          }, 700)
        );
    } catch (err) {
      console.error(err);
      setProductLoading(false);
    }
  }, [user, setProductLoading, setProductData, productId]);

  useEffect(() => {
    getProduct();
  }, [getProduct, setProductRefetch]);

  // Categories
  const [productCategoriesData, setProductCategoriesData] = useState([]);
  // const [productCategoriesLoading, setProductCategoriesLoading] = useState(true);
  // const [productCategoriesRefetch, setProductCategoriesRefetch] = useState(false);

  const getProductCategories = async () => {
    const { token } = user;
    try {
      // setProductCategoriesLoading(true);
      await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.categoriesGetAll}`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setProductCategoriesData(res.data);
          }
        })
        .catch((err) => console.error(err));
      // .finally(
      //   setTimeout(() => {
      //     setProductCategoriesLoading(false);
      //   }, 700)
      // );
    } catch (err) {
      console.error(err);
      // setProductCategoriesLoading(false);
    }
  };

  useEffect(() => {
    getProductCategories();
  }, []);

  let productCategoriesLoading = false;

  const values = useMemo(() => {
    return {
      form,
      setForm,
      onChange,
      onCancel,
      onSubmit,
      formError,
      setFormError,
      tabs,
      setTabs,
      productData,
      productLoading,
      productRefetch,
      setTabsGallery,
      onUpdate,
      elements,
      setElements,
      productEdit,
      setProductEdit,
      productCategoriesData,
      productDropdownOptions,
      setProductDropdownOptions,
      setProductCategoriesData,
      setRefetchGallery,
      createFormError,
      handleOnUpload,
      handleOnRemoveUpload,
      imageData,
      imagesArray,
      setImagesArray,
      productCategoriesLoading,
    };
  }, [form, setForm, imagesArray, setImagesArray, formError, setFormError, onChange, onCancel, onSubmit, tabs, setRefetchGallery, setTabs, setTabsGallery, productCategoriesData, productData, productLoading, productRefetch, onUpdate, elements, setElements, productEdit, setProductEdit, setProductDropdownOptions, productDropdownOptions, setProductCategoriesData, createFormError, handleOnUpload, imageData, productCategoriesLoading]);

  return <ReferencesContext.Provider value={values}>{children}</ReferencesContext.Provider>;
};

const UseReferences = () => {
  return useContext(ReferencesContext);
};

export { ReferencesProvider, UseReferences };
