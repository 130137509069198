import { PERMISSIONS } from './permissions';
import { getGroceryPermissions } from '../middleware';

const groceryMenuItems = (props) => {
  const { groceryPermissions, handleLogout, onHide } = props;
  const grocery = [
    {
      _id: '',
      label: 'Prodavnica',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/products.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
      onClick: function(value) {},
      children: [
        {
          _id: '',
          label: 'Atributi',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Proizvodi',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/products.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-products',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
          onClick: function() {
            onHide(false);
          },
          children: [],
        },
        {
          _id: '',
          label: 'Kategorije',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.categories}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/categories.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-categories',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCategories),
          onClick: function() {
            onHide(false);
          },
          children: [],
        },
      ],
    },
    {
      _id: '',
      label: 'Narudžbine',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/orders.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-orders',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Galerija',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/gallery.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-gallery',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readGallery),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Slideri',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.slideshow}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/slideshare.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-slideshow',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readSlideshow),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Baneri',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.banner}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/banners.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-banner',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readBanner),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Role',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.roles}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/roles.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-roles',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Korisnici',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.employees}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/employees.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-employees',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Brendovi',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.brands}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/brands.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-brands',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readBrands),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Blogovi',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blogs}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/blogs.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-blogs',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readBlog),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Reference',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.references}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/blogs.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-references',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readReference),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Deklaracije',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.declarations}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/declarations.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-declarations',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readDeclaration),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Newsletter',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.newsletters}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/banners.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-banner',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readBanner),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Odjavi se',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.login}`,
      group: 'account',
      type: 'button',
      icon: '/assets/icons/logout.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-logout',
      visible: true,
      onClick: () => {
        handleLogout();
      },
      children: [],
    },
  ];

  return grocery;
};

export { groceryMenuItems };
