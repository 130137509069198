const PERMISSIONS = {
  // STORE
  store: 'store',

  // PRODUCTS
  products: 'products',
  createProducts: 'create_products',
  readProducts: 'read_products',
  updateProducts: 'update_products',
  deleteProducts: 'delete_products',
  createProduct: 'create-product',
  updateProduct: 'update-product',
  productDetails: 'product-details',

  // ATTRIBUTES
  attributes: 'attributes',
  createAttributes: 'create_attributes',
  readAttributes: 'read_attributes',
  updateAttributes: 'update_attributes',
  deleteAttributes: 'delete_attributes',
  attributesByCatUrl: 'attributes-by-category-url',
  attributesByCatId: 'attributes-by-category-id',

  // ORDERS
  orders: 'orders',
  createOrders: 'create_orders',
  readOrders: 'read_orders',
  updateOrders: 'update_orders',
  deleteOrders: 'delete_orders',

  // GALLERY
  gallery: 'gallery',
  readGallery: 'read_images',
  createGallery: 'create_images',
  updateGallery: 'update_images',
  deleteGallery: 'delete_images',

  // SLIDESHOW
  slideshow: 'slideshow',
  readSlideshow: 'read_slideshow',
  createGallery: 'create_slideshow',
  updateGallery: 'update_slideshow',
  deleteGallery: 'delete_slideshow',

  // BANNER
  banner: 'banner',
  readBanner: 'read_banner',
  createBanner: 'create_banner',
  updateBanner: 'update_banner',
  deleteBanner: 'delete_banner',

  // ROLES
  roles: 'roles',
  createRoles: 'create_roles',
  readRoles: 'read_roles',
  updateRoles: 'update_roles',
  deleteRoles: 'delete_roles',

  // CUSTOMERS
  customers: 'customers',
  createCustomers: 'create_customers',
  readCustomers: 'read_customers',
  updateCustomers: 'update_customers',
  deleteCustomers: 'delete_customers',

  // EMPLOYEES
  employees: 'employees',
  createEmployees: 'create_employees',
  readEmployees: 'read_employees',
  updateEmployees: 'update_employees',
  deleteEmployees: 'delete_employees',

  // BRANDS
  brands: 'brands',
  createBrands: 'create_brands',
  readBrands: 'read_brands',
  updateBrands: 'update_brands',
  deleteBrands: 'delete_brands',

  // BLOGS
  blogs: 'blogovi',
  createBlog: 'create_blog',
  updateBlog: 'update_blog',
  readBlog: 'read_blog',
  deleteBlog: 'delete_blog',

  // References
  references: 'references',
  createReference: 'create_reference',
  updateReference: 'update_reference',
  readReference: 'read_reference',

  // Declarations
  declarations: 'declaration',
  readDeclaration: 'read_declaration',
  updateDeclaration: 'update_declaration',
  deleteDeclaration: 'delete_declaration',
  createDeclaration: 'create_declaration',

  // CATEGORIES
  categories: 'categories',
  createCategories: 'create_categories',
  readCategories: 'read_categories',
  updateCategories: 'update_categories',
  deleteCategories: 'delete_categories',

  // ACTIVITY
  activity: 'activity',
  readActivity: 'read_logs',

  // PROFILE
  profile: 'profile',
  readProfile: 'read_profile',
  updateProfile: 'update_profile',
  deleteProfile: 'delete_profile',

  // EMPLOYEE E-MAIL VERIFICATION
  employeeEmailVerification: 'employee-email-verification',

  // AUTH TYPE
  authType: 'basic',

  // SIGN IN PATH
  signInPath: 'dashboard',

  // LOGIN
  login: 'login',

  // FORGOT PASSWORD
  forgotPassword: 'forgot-password',

  // PASSWORD RESET
  passwordReset: 'password-reset',

  // DASHBOARD
  dashboard: 'admin',

  // GROCERY
  grocery: 'grocery',
  departmentGrocery: 'grocery',

  // NEWSLETTER
  newsletters: 'newsletters',
  readNewsLetter: 'read_newsletter',
  createNewsletter: 'create_newsletter',
  updateNewsletter: 'update_newsletter',
  deleteNewsletter: 'delete_newsletter',
};

const USER = 'user';

const SUPER_ADMIN = 'Super Admin';

export { PERMISSIONS, USER, SUPER_ADMIN };
