import React, { useEffect, useState } from 'react';
import { Section, Row, Col, Wrapper } from '../../../../components/content';
import { ProductProvider, useProducts } from '../../../../context/products';
import { ProductBreadcrumb } from './product-breadcrumb/ProductBreadcrumb';
import Loading from '../../../../components/loading/Loading';
import { ProductInfo } from '../create-product/product-info/ProductInfo';
import { ProductGallery } from '../create-product/product-gallery/ProductGallery';
import { ProductType } from '../create-product/product-type/ProductType';
import { ProductOptions } from '../create-product/product-options/ProductOptions';
import { ProductCreationDate } from '../create-product/product-date/ProductCreationDate';
import { ProductTags } from '../create-product/product-tags/ProductTags';
import LoadingPlaceholder from '../../../../components/loadingPlaceholder/LoadingPlaceholder';
import { PERMISSIONS } from '../../../../utils/permissions';
import ProductUpcCode from '../create-product/product-upccode/ProductUpcCode';
import { Declaration } from '../create-product/product-info/product-declaration/Declaration';
import axios from 'axios';
import { SERVER_URL } from '../../../../config/index';
import { useAuth } from '../../../../context/useAuth';
import { ProductAttributesUpdate } from '../update-product/product-attributes/ProductAttributesUpdate';

const UpdateProductLoader = (props) => {
  const { children } = props;
  const { productCategoriesLoading, setProductEdit } = useProducts();

  useEffect(() => {
    setProductEdit(true);
  }, []);

  if (productCategoriesLoading) {
    return <Loading className="loading-full loading-relative" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const UpdateProductSubmit = () => {
  const { form, onCancel, onUpdate, productCategoriesLoading } = useProducts();

  return (
    <footer className="product-save">
      <div className="actions">
        {!productCategoriesLoading ? (
          <button onClick={() => onCancel(form)} className="btn btn-primary-outline" type="button">
            <span className="text">Otkazati</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}

        {!productCategoriesLoading ? (
          <button onClick={() => onUpdate(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`)} className="btn btn-primary" type="button">
            <span className="text">Izmeni Proizvod</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}
      </div>
    </footer>
  );
};

const UpdateProduct = () => {
  const { user } = useAuth();
  const { token } = user;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const categoriesProps = {
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
  };

  const [attributesOptions, setAttributesOptions] = useState([]);
  const fetchSelectedSubCategoryAttributes = async () => {
    if (selectedSubCategory) {
      const res = await axios.post(
        `${SERVER_URL}/${PERMISSIONS.attributesByCatId}`,
        { id: selectedSubCategory, department: PERMISSIONS.grocery },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setAttributesOptions(res?.data?.length > 0 ? res?.data : []);
    }
  };

  useEffect(() => {
    fetchSelectedSubCategoryAttributes();
  }, [selectedSubCategory]);
  return (
    <ProductProvider>
      <ProductBreadcrumb />
      <Section className="section__product section__product--wrapper section__update-product product-form">
        <UpdateProductLoader>
          <Row>
            <Col>
              <Wrapper>
                <ProductInfo className="product__card--info" categoriesProps={{ ...categoriesProps }} />
              </Wrapper>
            </Col>
            <Col>
              <Wrapper className="side-right">
                <ProductCreationDate className="product__card--options" />

                <ProductGallery className="product__card--gallery" />
                {/* <ProductType className="product__card--type" /> */}
                <ProductOptions className="product__card--options" />
                {/* <ProductUpcCode className="product__card--upccode" /> */}
                <ProductTags className="product__card--tags" />
                <ProductAttributesUpdate attributesOptions={attributesOptions} selectedSubCategory={selectedSubCategory} />
                {/* <Declaration className="product-description" /> */}
              </Wrapper>
            </Col>
          </Row>
        </UpdateProductLoader>
        <UpdateProductSubmit />
      </Section>
    </ProductProvider>
  );
};

export default UpdateProduct;
