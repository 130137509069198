import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../../components/content';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import { getImageUrl } from '../../../helpers/helpers';
import SortableTree, { getTreeFromFlatData } from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import FeaturedCategoriesSelect from '../../../components/featuredCategoriesSelect/FeaturedCategoriesSelect';

const Sorting = (props) => {
  const { readData, handleToggle, location, groceryPermissions, getGroceryPermissions, setReadDataRefetch } = props;
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    let data = [];

    data = getTreeFromFlatData({
      flatData: readData.map((node) => ({ ...node, expanded: true })),
      getKey: (node) => node._id,
      getParentKey: (node) => node.parent,
      rootKey: null,
    });

    setTreeData(data);
  }, [readData, location, setTreeData]);

  function getImage(image) {
    if (image) {
      return true;
    }

    return false;
  }

  // TITLE
  const titleItem = (rowInfo) => {
    return [
      <h3 key={rowInfo.node._id.toString() + 'title'} className="title">
        <>
          {getImage(rowInfo.node.image) ? (
            <span className="image">
              <img alt="" src={getImageUrl(rowInfo.node.image.url)} className="img img-responsive" data-cy="category-real-image" />
            </span>
          ) : (
            <span className="image">
              <img alt="" src="/assets/images/placeholder-category.png" className="img img-responsive" data-cy="category-dummy-image" />
            </span>
          )}
        </>
        <span className="text" data-cy="category-name">
          {rowInfo.node.name}
        </span>
      </h3>,
    ];
  };

  // ACTIONS
  const actionButtons = (rowInfo) => {
    return [
      !rowInfo.node.parent && (
        <div key={rowInfo.node._id.toString() + '_add-wrapper'}>
          {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && (
            <button type="button" key={rowInfo.node._id.toString() + 'add'} data-cy={!rowInfo.node.parent ? 'parent-create-subcategory' : 'children-create-subcategory'} className="btn btn-categories" onClick={() => handleToggle({ name: 'createSubcategory', type: 'subcategory', data: rowInfo })}>
              <span className="icon">
                <img src="/assets/icons/plus.svg" alt="Add Category" className="img" />
              </span>
            </button>
          )}
        </div>
      ),
      <div key={rowInfo.node._id.toString() + '_edit-wrapper'}>
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateCategories) && (
          <button type="button" key={rowInfo.node._id.toString() + 'edit'} data-cy={!rowInfo.node.parent ? 'parent-update-category' : 'children-update-subcategory'} className="btn btn-categories" onClick={() => handleToggle({ name: 'update', data: rowInfo })}>
            <span className="icon">
              <img src="/assets/icons/edit.svg" alt="Edit Category" className="img" />
            </span>
          </button>
        )}
      </div>,
      <div key={rowInfo.node._id.toString() + '_delete-wrapper'}>
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteCategories) && (
          <button type="button" key={rowInfo.node._id.toString() + 'delete'} data-cy={!rowInfo.node.parent ? 'parent-delete-category' : 'children-delete-subcategory'} className="btn btn-categories" onClick={() => handleToggle({ name: 'delete', data: rowInfo })}>
            <span className="icon">
              <img src="/assets/icons/delete.svg" alt="Delete Category" className="img" />
            </span>
          </button>
        )}
      </div>,
    ];
  };

  const generateNodeProps = (rowInfo) => {
    const buttons = [];

    // Add a 'plus' button only if the node is at the top level
    if (!rowInfo.node.parent) {
      buttons.push(<button onClick={() => console.log('Add child logic here')}>+</button>);
    }

    return {
      title: titleItem(rowInfo),
      buttons: actionButtons(rowInfo),
    };
  };

  return (
    <Wrapper>
      {treeData && treeData.length >= 2 ? <FeaturedCategoriesSelect parentCategories={treeData} allCategories={readData} setReadDataRefetch={setReadDataRefetch} /> : <div></div>}
      {treeData && treeData.length > 0 ? <SortableTree className="sorting" canDrag={false} treeData={treeData} canDrop={false} isVirtualized={false} rowHeight={110} onChange={(data) => setTreeData(data)} style={{ paddingLeft: 30 }} generateNodeProps={generateNodeProps} /> : null}

      {treeData && treeData.length === 0 ? (
        <Message className="content-message">
          <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
        </Message>
      ) : null}
    </Wrapper>
  );
};

export default Sorting;
