import React from 'react';
import { SERVER_URL } from '../../../config/index';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import axios from 'axios';

export const SortableList = ({ children }) => {
  const clonedItems = React.useMemo(() => {
    return React.Children.map(children, (child, index) => ({
      id: index,
      content: child,
    }));
  }, [children]);

  const [items, setItems] = React.useState(clonedItems);

  const [dragOverIndex, setDragOverIndex] = React.useState(-1);
  const dragNode = React.useRef();

  const handleDragStart = (e, index) => {
    const { target } = e;

    dragNode.current = target;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', target);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    const currentDragNode = dragNode.current;
    if (currentDragNode && currentDragNode !== e.target && !currentDragNode.contains(e.target)) {
      setDragOverIndex(index);
    }
  };

  const handleDragEnd = async (e, index) => {
    setDragOverIndex(-1);
    if (dragOverIndex === -1 || index === dragOverIndex) {
      return;
    }
    let newItems = [...items];
    const draggingItem = newItems[dragOverIndex];
    const targetItem = newItems[index];

    newItems[dragOverIndex] = targetItem;
    newItems[index] = draggingItem;

    setItems(newItems);
    await axios.put(`${SERVER_URL}/${AXIOS_API_CALL.replaceOrderOfSliders}`, { indexTo: index, indexFrom: dragOverIndex });
  };

  return (
    <>
      {items.map((item, index) => (
        <div className={index === dragOverIndex ? 'highlight-samo-primer' : ''} key={item.id} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={(e) => handleDragOver(e, index)} onDragEnd={(e) => handleDragEnd(e, index)}>
          {item.content}
        </div>
      ))}
    </>
  );
};
